import React from "react";
import ReviewCard from "../Components/ReviewCard";
import Grid from "@mui/material/Unstable_Grid2";

function Reviews() {
	return (
		<>
			<h3
				style={{ fontSize: "1.5em", fontFamily: "Lucida Console" }}
				id="testimonials">
				Testimonials
			</h3>
			<Grid container spacing={2} sx={{ marginBottom: "10px" }}>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						stars="5"
						time="04/10/2023 12:01:04"
						author="David Anderkavich"
						link="https://goo.gl/maps/vNvCxXJSYHjMnVjE6"
						review="Amazing job by Ingoglia Roofing Specialist Inc.! From start to finish, the crew was awesome. They were respectful and provided a thorough cleanup. I received numerous updates and everything was as I was told. I can’t thank them enough for the awesome job they did. I will refer Ingoglia Roofing to everyone. Great job!!"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						stars="5"
						time="06/10/2022 14:45:10"
						author="Jarrodnns Barnes"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNPN29yLWpRRRAB!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgICO7or-jQE%7CCgwI9rGNlQYQ2OfLpAE%7C?hl=en-US" //lh3.googleusercontent.com/a/ALm5wu1vF-jPKHlKd0yBJc5kRiDe_AlrCH-ivsyhiFGb=s120-c-c0x00000000-cc-rp-mo-br100""
						review="I would highly recommend this business for your roofing needs. Replacing/ repairing a roof can be overwhelming and expensive. Joe was amazing to work with, honest, and upfront about all aspects of my roof. Joe and his crew hit the ground running and didn't slow until the job was complete. His attention to detail and commitment to customer service is second to none."
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						stars="5"
						time="10/05/2022 23:53:23"
						author="Kevin Ulrich"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURlb3NuRFV3EAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIDeosnDUw%7CCgwI87H4mQYQuLK9swM%7C?hl=en-US"
						review="Joe was awesome and upfront about everything. He walked me through the entire process and made me feel great about my repairs. Roof repairs can be extremely stressful. Joe and his team were respectful and professional and make things so much easier. I will use him in the future for all my repair needs."
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						stars="5"
						time="10/13/2022 10:32:46"
						author="Chase DeMarco"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURlaWF2TmR3EAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIDeiavNdw%7CCgsIztKfmgYQ0N6CPA%7C?hl=en-US"
						review="From bid to complete in a week! Couldn't believe how fast the turnaround was. They finished the roof in two days! After they left, it was like they weren't ever there. No nails and no debris left from the old roof. I've never seen a roofing company that cleans up like this. Very professional, prompt, and just good people. Worth every penny!"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						stars="5"
						time="10/14/2022 01:35:47"
						author="Brittany Jones"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURlbWVLVVB3EAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIDemeKUPw%7CCgsI8_mimgYQ2M27Sw%7C?hl=en-US"
						review="AMAZING! My family was displaced from our home due to damage from Hurricane Ian. Joseph and his crew responded immediately and was able to get my family back home way sooner than we ever imagined. Never have I had to deal with a situation like this before. Joseph provided assistance and guidance throughout the entire process. 100% recommended!"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Anna Fisher"
						review="This is was the most amazing service ever!! Came out on an emergency leak on a Sunday. Not only he looked at the damage to assess it but fixed it the same day knowing the urgency of our of situation.\n\nProfessional quality contractor and work. Highly recommended \ud83d\ude0a"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUR1OU1UYVl3EAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIDu9MTaYw%7CCgwIoKHllwYQ6P-2qwM%7C?hl=en-US"
						stars="5"
						time="08/14/2022 19:44:32"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Half Halt Stables Dressage"
						review="Super professional and fast working group of people! We needed a new roof at our horse farm and they got it done in less then 2 days. Thank you so much for an fantastic job!"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURld1lybTJ3RRAB!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIDewYrm2wE%7CCgwIveaXmgYQoL_akAI%7C?hl=en-US"
						stars="5"
						time="10/11/2022 22:50:37"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Deni Weremeichik"
						review="I would absolutely recommend this business for your roofing needs.  Joe was a real prince to come out and help us on a Sunday.  In a time when not only workers don\u2019t show  up but then do a poor job it makes me appreciate Ingoglia\u2019s commitment to customer service. I hope you give them the opportunity to work for you because you won\u2019t be disappointed."
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNtcS1YcmxnRRAB!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgICmq-XrlgE%7CCgsI1rHtjgYQsK77Ww%7C?hl=en-US"
						stars="5"
						time="01/09/2022 21:51:18"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Joe Capece"
						review="Joey and Joe Ingoglia along with their crew replaced my roof as well as replaced damaged plywood.  They were prompt, courteous and professional throughout the entire process.  The work performed was top notch at a very competitive price!  Thank you Ingoglia Roof Specialists!"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURHbnUtTGlnRRAB!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIDGnu-LigE%7CCgwImY2SjQYQiL3t7wE%7C?hl=en-US"
						stars="5"
						time="11/29/2021 07:51:53"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Sharon Mercurio"
						review="Outstanding customer service!! Called them to set up an appointment for a roof inspection  & they came by within an hour and called us shortly there after with the information. They also provided a written description  with photos.Thank  you Joe for the fantastic service!"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURXZ3Z1Q1FBEAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIDWgvuCQA%7CCgwI4dDTkQYQyOyXugE%7C?hl=en-US"
						stars="5"
						time="03/18/2022 20:15:29"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Donna Gomez"
						review="I called Ingoglia Roofing Specialist for a hole around a pipe that was covered on my roof. Joseph Ingoglia sent his employee out to fix the roof the same day. His employee (Joe) was very professional and fixed the roof the way it should have been done in the first place.\n\nIngoglia roofing will be my roofer for any job big or small. Thanks again Ingoglia Roofing!"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNXLXBIeDF3RRAB!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgICW-pHx1wE%7CCgwI2Y7gkAYQ6PvL4gE%7C?hl=en-US"
						stars="5"
						time="02/24/2022 22:31:53"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Melissa N"
						review="The team from Ingoglia Roofing specialists mobilized quickly when I had an urgent need for the cleaning and repair of a roof on an investment home. It's a very professional company with fair pricing, good work ethic, and great results. It was a tough task and they tackled it with ease.  I am happy to recommend Ingoglia Roof Specialist and will use this company in the future on my own home."
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNHa3NYbW93RRAB!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgICGksXmowE%7CCgsI2K7XiwYQoKiOJA%7C?hl=en-US"
						stars="5"
						time="10/24/2021 21:59:52"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Michael Marrone"
						review="They're a roofing company, but they also paint houses.  In my case, they did a great job with my house.  Highly recommend."
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUMyLXAtaVd3EAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgIC2-p-iWw%7CCgwIzr34kgYQsP2PkgE%7C?hl=en-US"
						stars="5"
						time="04/19/2022 02:30:38"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="gary krause"
						review="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNtaHI3Y1JnEAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgICmhr7cRg%7CCgwI5dmijgYQqKjt1gI%7C?hl=en-US"
						link="ttps://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNtaHI3Y1JnEAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgICmhr7cRg%7CCgwI5dmijgYQqKjt1gI%7C?hl=en-US"
						stars="5"
						time="12/26/2021 17:56:53"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Rhonda Huckelbery"
						review="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUQ2cFBpaWN3EAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgID6pPiicw%7CCgwInrakigYQ0JTotQM%7C?hl=en-US"
						link="https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUQ2cFBpaWN3EAE!2m1!1s0x0:0x8bc7c276dc333a7a!3m1!1s2@1:CIHM0ogKEICAgID6pPiicw%7CCgwInrakigYQ0JTotQM%7C?hl=en-US"
						stars="5"
						time="09/20/2021 23:37:02"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="Pablo Colon"
						review="I called Ingoglia roofing company after the last set of storms because I had water damage. They responded the next day to inspect my roof. He checked my whole roof and found no actual damage from the storm. He did tar some shingles back down that were loose at no cost. He was very respectful and knowledgeable on his craft. He did promise to come back after some more rain, in which he did keep his word. He recently called me to remind me to check my insurance policy due to hurricane season coming around the corner.I recommend them to anyone who may have issues."
						link="https://g.co/kgs/8CSnTT"
						stars="5"
						time="05/31/2023 24:40:04"
					/>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<ReviewCard
						author="David Komarowsky"
						review="We had the privilege of doing business with Ingoglia Roofing Specialists, Joe the Owner was very Informative and Professional. From beginning to end the process was very easy and done on time. Excellent workmanship and Excellent Quality materials. Highly Recommended."
						link="https://g.co/kgs/TjQoFC"
						stars="5"
						time="05/30/2023 12:40:04"
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default Reviews;
