import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import sendmail from "../images/sendmail.png";
import callus from "../images/callus.png";

function SocialDiv() {
	return (
		<div id="contactus" style={{ backgroundColor: "#F5F5F5" }}>
			<footer
				style={{
					padding: "0",
					textAlign: "center",
					marginBottom: "0",
					fontSize: "12px",
					backgroundColor: "charcoal",
				}}>
				<div
					className="social"
					style={{ padding: "5px", margin: "10px" }}>
					<a href="https://www.instagram.com/ingoglia_roofing_specialist/">
						<img
							style={{ height: "35px", margin: "10px" }}
							alt="instagram"
							src={instagram}></img>
					</a>
					<a href="mailto:jingoglia0226@gmail.com">
						<img
							style={{ height: "35px", margin: "10px" }}
							alt="mail"
							src={sendmail}></img>
					</a>
					<a href="https://www.facebook.com/IngogliaRoofingSpecialist/">
						<img
							style={{ height: "35px", margin: "10px" }}
							alt="facebook"
							src={facebook}></img>
					</a>
					<a href="tel:+14078788724">
						<img
							style={{ height: "35px", margin: "10px" }}
							alt="phone"
							src={callus}></img>
					</a>
					<p className="copyright">
						Ingoglia Roofing Specialist Inc.
					</p>
					<p>Licensed & Insured - #CCC1333269</p>
					<p className="copyright">West Melbourne, FL</p>
					<p className="copyright">+1 (407) 878 8724</p>
				</div>
				<hr></hr>
				<p
					style={{ fontSize: ".7em", color: "rgb(75,0,130)" }}
					className="design">
					Website design by SprinklingsDesigns - Joeystabi01@gmail.com
				</p>
			</footer>
		</div>
	);
}

export default SocialDiv;
