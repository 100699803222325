import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "./Card";
import soffit from "../images/soffit.png";
import tarp from "../images/tarp.png";
import roofrepair from "../images/roofrepair.png";
import shingleroof from "../images/shingleroof.png";
import atticvent from "../images/atticvent.png";
import roofrecoat from "../images/roofrecoat.png";
import Flippy, { FrontSide, BackSide } from "react-flippy";

function Services() {
	return (
		<React.Fragment>
			<h1
				id="updates"
				style={{ fontSize: "1.5em", fontFamily: "Lucida Console" }}>
				Our Services
			</h1>
			<p style={{ textAlign: "center" }}>
				Family owned and operated contracting company providing a
				multitude of services to include, but not limited to the
				following: Wind/Storm Damage Repairs, Re-Roofing, New
				Construction, Pressure Washing, Gutter Cleaning, Roof Cleaning,
				& Painting, Resin flooring, flakes and metallic epoxy coatings.
			</p>
			<Grid container spacing={2} sx={{ marginBottom: "10px" }}>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<Flippy
						flipOnHover={false}
						flipOnClick={true}
						flipDirection="vertical"
						style={{ backgroundColor: "rgb(48,48,48)" }}>
						<FrontSide>
							<Card title="Roof Repairs" img={roofrepair} />
						</FrontSide>
						<BackSide>
							<Card
								alt=""
								title="Roof Repairs"
								text="Our team is equipped with the knowledge and skills to install or replace roofing on your residential or commercial buildings. We can also help to repair storm damage and will work to install everything properly, so you don’t have to worry for years to come."
							/>
						</BackSide>
					</Flippy>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<Flippy
						flipOnHover={false}
						flipOnClick={true}
						flipDirection="vertical"
						style={{ backgroundColor: "rgb(48,48,48)" }}>
						<FrontSide>
							<Card
								img={shingleroof}
								title="Shingle Roof Replacements"
							/>
						</FrontSide>
						<BackSide>
							<Card
								alt=""
								title="Shingle Roof Replacements"
								text="A roof replacement is tearing off your old roofing materials down to the decking and installing all-new roofing components. Our experts are equipped to handle this process start to finish with 5 star results."
							/>
						</BackSide>
					</Flippy>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<Flippy
						flipOnHover={false}
						flipOnClick={true}
						flipDirection="vertical"
						style={{ backgroundColor: "rgb(48,48,48)" }}>
						<FrontSide>
							<Card img={atticvent} title="Attic Ventilation" />
						</FrontSide>
						<BackSide>
							<Card
								alt=""
								title="Attic Ventilation"
								text="Attic ventilation is a critical component to creating the most energy-efficient environment in your attic. Along with increasing the energy efficiency of your attic and your home, we will also make sure your attic meets local requirements of building codes for good attic ventilation."
							/>
						</BackSide>
					</Flippy>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<Flippy
						flipOnHover={false}
						flipOnClick={true}
						flipDirection="vertical"
						style={{ backgroundColor: "rgb(48,48,48)" }}>
						<FrontSide>
							<Card img={roofrecoat} title="Roof Re-Coatings" />
						</FrontSide>
						<BackSide>
							<Card
								alt=""
								title={"Roof Re-Coatings"}
								text="Roof coating is the application of a membrane to the outer surface of your roof that provides extra durability and longevity. A recoat service is reapplying a new, fresh layer of this protective coat to the surface of your roof."
							/>
						</BackSide>
					</Flippy>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<Flippy
						flipOnHover={false}
						flipOnClick={true}
						flipDirection="vertical"
						style={{
							backgroundColor: "rgb(48,48,48)",
							padding: "5px",
						}}>
						<FrontSide>
							<Card img={soffit} title="Soffit Repairs" />
						</FrontSide>
						<BackSide>
							<Card
								alt=""
								title="Soffit Repairs"
								text="Soffit is the exposed area underneath your roof’s overhang.  Soffits may be made of wood, aluminum or vinyl.  Soffits are an important part of your roof ventilation; they assist with airflow in the attic. Our specialists will work with you to ensure this process is completed thoroughly to ensure quality work the first time."
							/>
						</BackSide>
					</Flippy>
				</Grid>
				<Grid xs={12} md={4} style={{ display: "flex" }}>
					<Flippy
						flipOnHover={false}
						flipOnClick={true}
						flipDirection="vertical"
						style={{ backgroundColor: "rgb(48,48,48)" }}>
						<FrontSide>
							<Card img={tarp} title="Emergency Tarp Covering" />
						</FrontSide>
						<BackSide>
							<Card
								alt=""
								title="Emergency Tarp Covering"
								text="Emergency roof tarping is a temporary solution to prevent secondary damage to your roof until it can be thoroughly inspected and repaired. If you suspect that your roof has succumbed to damage from a recent storm or accident, we can provide you with emergency roof tarping services."
							/>
						</BackSide>
					</Flippy>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default Services;
