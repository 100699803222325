import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import oct9 from "../images/gallery/oct9.jpg";
import oct9two from "../images/gallery/oct9two.jpg";
import feb21 from "../images/gallery/feb21.jpg";
import feb21two from "../images/gallery/feb21two.jpg";
import sep13 from "../images/gallery/sep13.jpg";
import oct22 from "../images/gallery/oct22.jpg";
import oct22three from "../images/gallery/oct22three.jpg";
import oct22four from "../images/gallery/oct22four.jpg";
import twodec22 from "../images/gallery/twodec22.jpg";
import twodec222 from "../images/gallery/twodec222.jpg";
import befafapr22 from "../images/gallery/befafapr22.jpg";
import AA from "../images/gallery/AA.jpg";
import AB from "../images/gallery/AB.jpg";
import AC from "../images/gallery/AC.jpg";
import AD from "../images/gallery/AD.jpg";
import AE from "../images/gallery/AE.jpg";
import AF from "../images/gallery/AF.jpg";
import AG from "../images/gallery/AG.jpg";
import AH from "../images/gallery/AH.jpg";
import AI from "../images/gallery/AI.jpg";
import AJ from "../images/gallery/AJ.jpg";
import AK from "../images/gallery/AK.jpg";
import AL from "../images/gallery/AL.jpg";
import AM from "../images/gallery/AM.jpg";
import AN from "../images/gallery/AN.jpg";
import AO from "../images/gallery/AO.jpg";
import AP from "../images/gallery/AP.jpg";
import AQ from "../images/gallery/AQ.jpg";
import AR from "../images/gallery/AR.jpg";
import AS from "../images/gallery/AS.jpg";
import AT from "../images/gallery/AT.jpg";
import AV from "../images/gallery/AV.jpg";
import AW from "../images/gallery/AW.jpg";
import AX from "../images/gallery/AX.jpg";
import AY from "../images/gallery/AY.jpg";
import AZ from "../images/gallery/AZ.jpg";
import BA from "../images/gallery/BA.jpg";
import BB from "../images/gallery/BB.jpg";
import BC from "../images/gallery/BC.jpg";
import BD from "../images/gallery/BD.jpg";
import BE from "../images/gallery/BE.jpg";
import BF from "../images/gallery/BF.jpg";
import BG from "../images/gallery/BG.jpg";
import BH from "../images/gallery/BH.jpg";
import BI from "../images/gallery/BI.jpg";
import BJ from "../images/gallery/BJ.jpg";
import BK from "../images/gallery/BK.jpg";
import BL from "../images/gallery/BL.jpg";
import BM from "../images/gallery/BM.jpg";
import BN from "../images/gallery/BN.jpg";
import BO from "../images/gallery/BO.jpg";
import BP from "../images/gallery/BP.jpg";
import BQ from "../images/gallery/BQ.jpg";
import BR from "../images/gallery/BR.jpg";
import BS from "../images/gallery/BS.jpg";
import BV from "../images/gallery/BV.jpg";
import BW from "../images/gallery/BW.jpg";
import BX from "../images/gallery/BX.jpg";
import BY from "../images/gallery/BY.jpg";
import BZ from "../images/gallery/BZ.jpg";
import CA from "../images/gallery/CA.jpg";
import CB from "../images/gallery/CB.jpg";
import CC from "../images/gallery/CC.jpg";
import CD from "../images/gallery/CD.jpg";
import CE from "../images/gallery/CE.jpg";
import CF from "../images/gallery/CF.jpg";
import CG from "../images/gallery/CG.jpg";
import CH from "../images/gallery/CH.jpg";
import CI from "../images/gallery/CI.jpg";
import CJ from "../images/gallery/CJ.jpg";
import CK from "../images/gallery/CK.jpg";
import CL from "../images/gallery/CL.jpg";
import CM from "../images/gallery/CM.jpg";
import CN from "../images/gallery/CN.jpg";
import CO from "../images/gallery/CO.jpg";
import CP from "../images/gallery/CP.jpg";
import CQ from "../images/gallery/CQ.jpg";
import CR from "../images/gallery/CR.jpg";
import CS from "../images/gallery/CS.jpg";
import CT from "../images/gallery/CT.jpg";
import CV from "../images/gallery/CV.jpg";
import CW from "../images/gallery/CW.jpg";
import CX from "../images/gallery/CX.jpg";
import CY from "../images/gallery/CY.jpg";
import CZ from "../images/gallery/CZ.jpg";
import DA from "../images/gallery/DA.jpg";
import DB from "../images/gallery/DB.jpg";
import DC from "../images/gallery/DC.jpg";
import DD from "../images/gallery/DD.jpg";
import DE from "../images/gallery/DE.jpg";
import DF from "../images/gallery/DF.jpg";
import DG from "../images/gallery/DG.jpg";
import DH from "../images/gallery/DH.jpg";
import DI from "../images/gallery/DI.jpg";
import DJ from "../images/gallery/DJ.jpg";
import DK from "../images/gallery/DK.jpg";
import DL from "../images/gallery/DL.jpg";
import DM from "../images/gallery/DM.jpg";
import DN from "../images/gallery/DN.jpg";
import DO from "../images/gallery/DO.jpg";
import DP from "../images/gallery/DP.jpg";
import DQ from "../images/gallery/DQ.jpg";
import DR from "../images/gallery/DR.jpg";
import DS from "../images/gallery/DS.jpg";
import DT from "../images/gallery/DT.jpg";
import DV from "../images/gallery/DV.jpg";
import DW from "../images/gallery/DW.jpg";
import DX from "../images/gallery/DX.jpg";
import DY from "../images/gallery/DY.jpg";
import DZ from "../images/gallery/DZ.jpg";
import EA from "../images/gallery/EA.jpg";
import EB from "../images/gallery/EB.jpg";
import EC from "../images/gallery/EC.jpg";
import ED from "../images/gallery/ED.jpg";
import EE from "../images/gallery/EE.jpg";
import EF from "../images/gallery/EF.jpg";
import EG from "../images/gallery/EG.jpg";
import EH from "../images/gallery/EH.jpg";
import EI from "../images/gallery/EI.jpg";
import EJ from "../images/gallery/EJ.jpg";
import EK from "../images/gallery/EK.jpg";
import EL from "../images/gallery/EL.jpg";
import EM from "../images/gallery/EM.jpg";
import EN from "../images/gallery/EN.jpg";
import EO from "../images/gallery/EO.jpg";
import EP from "../images/gallery/EP.jpg";
import EQ from "../images/gallery/EQ.jpg";
import ER from "../images/gallery/ER.jpg";
import ES from "../images/gallery/ES.jpg";
import ET from "../images/gallery/ET.jpg";
import EV from "../images/gallery/EV.jpg";
import EW from "../images/gallery/EW.jpg";
import EX from "../images/gallery/EX.jpg";
import EY from "../images/gallery/EY.jpg";
import EZ from "../images/gallery/EZ.jpg";
import FB from "../images/gallery/FB.jpg";
import FD from "../images/gallery/FD.jpg";
import FH from "../images/gallery/FH.jpg";
import FI from "../images/gallery/FI.jpg";
import FJ from "../images/gallery/FJ.jpg";
import FK from "../images/gallery/FK.jpg";
import FL from "../images/gallery/FL.jpg";
import FM from "../images/gallery/FM.jpg";
import FN from "../images/gallery/FN.jpg";
import FO from "../images/gallery/FO.jpg";
import FP from "../images/gallery/FP.jpg";
import FQ from "../images/gallery/FQ.jpg";
import FR from "../images/gallery/FR.jpg";
import FS from "../images/gallery/FS.jpg";
import FT from "../images/gallery/FT.jpg";
import FV from "../images/gallery/FV.jpg";
import FW from "../images/gallery/FW.jpg";
import FX from "../images/gallery/FX.jpg";
import FY from "../images/gallery/FY.jpg";
import FZ from "../images/gallery/FZ.jpg";
import GA from "../images/gallery/GA.jpg";
import GB from "../images/gallery/GB.jpg";
import GC from "../images/gallery/GC.jpg";
import GD from "../images/gallery/GD.jpg";
import GE from "../images/gallery/GE.jpg";
import GF from "../images/gallery/GF.jpg";
import GG from "../images/gallery/GG.jpg";
import GH from "../images/gallery/GH.jpg";
import GI from "../images/gallery/GI.jpg";
import GJ from "../images/gallery/GJ.jpg";
import GK from "../images/gallery/GK.jpg";
import GL from "../images/gallery/GL.jpg";
import GM from "../images/gallery/GM.jpg";
import GN from "../images/gallery/GN.jpg";
import GO from "../images/gallery/GO.jpg";
import GP from "../images/gallery/GP.jpg";
import GQ from "../images/gallery/GQ.jpg";
import GR from "../images/gallery/GR.jpg";
import GS from "../images/gallery/GS.jpg";
import GT from "../images/gallery/GT.jpg";
import GV from "../images/gallery/GV.jpg";
import GW from "../images/gallery/GW.jpg";
import GX from "../images/gallery/GX.jpg";
import GY from "../images/gallery/GY.jpg";
import GZ from "../images/gallery/GZ.jpg";
import HA from "../images/gallery/HA.jpg";
import HB from "../images/gallery/HB.jpg";
import HC from "../images/gallery/HC.jpg";
import HD from "../images/gallery/HD.jpg";
import HE from "../images/gallery/HE.jpg";
import HF from "../images/gallery/HF.jpg";
import HG from "../images/gallery/HG.jpg";
import HH from "../images/gallery/HH.jpg";
import HI from "../images/gallery/HI.jpg";
import HJ from "../images/gallery/HJ.jpg";
import HK from "../images/gallery/HK.jpg";
import HL from "../images/gallery/HL.jpg";
import HM from "../images/gallery/HM.jpg";
import HN from "../images/gallery/HN.jpg";
import HO from "../images/gallery/HO.jpg";
import HP from "../images/gallery/HP.jpg";
import HQ from "../images/gallery/HQ.jpg";
import HR from "../images/gallery/HR.jpg";
import HT from "../images/gallery/HT.jpg";
import HV from "../images/gallery/HV.jpg";
import HW from "../images/gallery/HW.jpg";

function ImgGallery2() {
	return (
		<>
			<h3
				style={{ fontSize: "1.5em", fontFamily: "Lucida Console" }}
				id="allimages">
				All Images
			</h3>
			<ImageList
				sx={{ width: "90%", height: "90%", margin: "auto" }}
				cols={3}
				rowHeight={"30%"}>
				{itemData.map((item) => (
					<ImageListItem key={item.img}>
						<img
							src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
							srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
							alt={item.title}
							loading="lazy"
						/>
					</ImageListItem>
				))}
			</ImageList>
		</>
	);
}

export default ImgGallery2;

const itemData = [
	{
		img: oct9,
		title: "9 Oct 2022 (1)",
	},
	{
		img: oct9two,
		title: "9 Oct 2022 (2)",
	},
	{
		img: feb21,
		title: "21 Feb 2022 (1)",
	},
	{
		img: feb21two,
		title: "21 Feb 2022 (2)",
	},
	{
		img: oct22,
		title: "22 Oct 2022 (1))",
	},

	{
		img: oct22three,
		title: "22 Oct 2022 (3)",
	},
	{
		img: oct22four,
		title: "22 Oct 2022 (4)",
	},
	{
		img: sep13,
		title: "13 Sep 2022 (1)",
	},
	{
		img: twodec22,
		title: "02 Dec 2022 (1)",
	},
	{
		img: twodec222,
		title: "02 Dec 2022 (2)",
	},
	{
		img: befafapr22,
		title: "Apr 2022",
	},
	{
		img: AA,
		title: "AA",
	},
	{
		img: AB,
		title: "AB",
	},
	{
		img: AC,
		title: "AC",
	},
	{
		img: AD,
		title: "AD",
	},
	{
		img: AE,
		title: "AE",
	},
	{
		img: AF,
		title: "AF",
	},
	{
		img: AG,
		title: "AG",
	},
	{
		img: AH,
		title: "AH",
	},
	{
		img: AI,
		title: "AI",
	},
	{
		img: AJ,
		title: "AJ",
	},
	{
		img: AK,
		title: "AK",
	},
	{
		img: AL,
		title: "AL",
	},
	{
		img: AM,
		title: "AM",
	},
	{
		img: AN,
		title: "AN",
	},
	{
		img: AO,
		title: "AO",
	},
	{
		img: AP,
		title: "AP",
	},
	{
		img: AQ,
		title: "AQ",
	},
	{
		img: AR,
		title: "AR",
	},
	{
		img: AS,
		title: "AS",
	},
	{
		img: AT,
		title: "AT",
	},

	{
		img: AV,
		title: "AV",
	},
	{
		img: AW,
		title: "AW",
	},
	{
		img: AX,
		title: "AX",
	},
	{
		img: AY,
		title: "AY",
	},
	{
		img: AZ,
		title: "AZ",
	},
	{
		img: BA,
		title: "BA",
	},
	{
		img: BB,
		title: "BB",
	},
	{
		img: BC,
		title: "BC",
	},
	{
		img: BD,
		title: "BD",
	},
	{
		img: BE,
		title: "BE",
	},
	{
		img: BF,
		title: "BF",
	},
	{
		img: BG,
		title: "BG",
	},
	{
		img: BH,
		title: "BH",
	},
	{
		img: BI,
		title: "BI",
	},
	{
		img: BJ,
		title: "BJ",
	},
	{
		img: BK,
		title: "BK",
	},
	{
		img: BL,
		title: "BL",
	},
	{
		img: BM,
		title: "BM",
	},
	{
		img: BN,
		title: "BN",
	},
	{
		img: BO,
		title: "BO",
	},
	{
		img: BP,
		title: "BP",
	},
	{
		img: BQ,
		title: "BQ",
	},
	{
		img: BR,
		title: "BR",
	},
	{
		img: BS,
		title: "BS",
	},

	{
		img: BV,
		title: "BV",
	},
	{
		img: BW,
		title: "BW",
	},
	{
		img: BX,
		title: "BX",
	},
	{
		img: BY,
		title: "BY",
	},
	{
		img: BZ,
		title: "BZ",
	},
	{
		img: CA,
		title: "CA",
	},
	{
		img: CB,
		title: "CB",
	},
	{
		img: CC,
		title: "CC",
	},
	{
		img: CD,
		title: "CD",
	},
	{
		img: CE,
		title: "CE",
	},
	{
		img: CF,
		title: "CF",
	},
	{
		img: CG,
		title: "CG",
	},
	{
		img: CH,
		title: "CH",
	},
	{
		img: CI,
		title: "CI",
	},
	{
		img: CJ,
		title: "CJ",
	},
	{
		img: CK,
		title: "CK",
	},
	{
		img: CL,
		title: "CL",
	},
	{
		img: CM,
		title: "CM",
	},
	{
		img: CN,
		title: "CN",
	},
	{
		img: CO,
		title: "CO",
	},
	{
		img: CP,
		title: "CP",
	},
	{
		img: CQ,
		title: "CQ",
	},
	{
		img: CR,
		title: "CR",
	},
	{
		img: CS,
		title: "CS",
	},
	{
		img: CT,
		title: "CT",
	},

	{
		img: CV,
		title: "CV",
	},
	{
		img: CW,
		title: "CW",
	},
	{
		img: CX,
		title: "CX",
	},
	{
		img: CY,
		title: "CY",
	},
	{
		img: CZ,
		title: "CZ",
	},
	{
		img: DA,
		title: "DA",
	},
	{
		img: DB,
		title: "DB",
	},
	{
		img: DC,
		title: "DC",
	},
	{
		img: DD,
		title: "DD",
	},
	{
		img: DE,
		title: "DE",
	},
	{
		img: DF,
		title: "DF",
	},
	{
		img: DG,
		title: "DG",
	},
	{
		img: DH,
		title: "DH",
	},
	{
		img: DI,
		title: "DI",
	},
	{
		img: DJ,
		title: "DJ",
	},
	{
		img: DK,
		title: "DK",
	},
	{
		img: DL,
		title: "DL",
	},
	{
		img: DM,
		title: "DM",
	},
	{
		img: DN,
		title: "DN",
	},
	{
		img: DO,
		title: "DO",
	},
	{
		img: DP,
		title: "DP",
	},
	{
		img: DQ,
		title: "DQ",
	},
	{
		img: DR,
		title: "DR",
	},
	{
		img: DS,
		title: "DS",
	},
	{
		img: DT,
		title: "DT",
	},

	{
		img: DV,
		title: "DV",
	},
	{
		img: DW,
		title: "DW",
	},
	{
		img: DX,
		title: "DX",
	},
	{
		img: DY,
		title: "DY",
	},
	{
		img: DZ,
		title: "DZ",
	},
	{
		img: EA,
		title: "EA",
	},
	{
		img: EB,
		title: "EB",
	},
	{
		img: EC,
		title: "EC",
	},
	{
		img: ED,
		title: "ED",
	},
	{
		img: EE,
		title: "EE",
	},
	{
		img: EF,
		title: "EF",
	},
	{
		img: EG,
		title: "EG",
	},
	{
		img: EH,
		title: "EH",
	},
	{
		img: EI,
		title: "EI",
	},
	{
		img: EJ,
		title: "EJ",
	},
	{
		img: EK,
		title: "EK",
	},
	{
		img: EL,
		title: "EL",
	},
	{
		img: EM,
		title: "EM",
	},
	{
		img: EN,
		title: "EN",
	},
	{
		img: EO,
		title: "EO",
	},
	{
		img: EP,
		title: "EP",
	},
	{
		img: EQ,
		title: "EQ",
	},
	{
		img: ER,
		title: "ER",
	},
	{
		img: ES,
		title: "ES",
	},
	{
		img: ET,
		title: "ET",
	},

	{
		img: EV,
		title: "EV",
	},
	{
		img: EW,
		title: "EW",
	},
	{
		img: EX,
		title: "EX",
	},
	{
		img: EY,
		title: "EY",
	},
	{
		img: EZ,
		title: "EZ",
	},

	{
		img: FB,
		title: "FB",
	},

	{
		img: FD,
		title: "FD",
	},

	{
		img: FH,
		title: "FH",
	},
	{
		img: FI,
		title: "FI",
	},
	{
		img: FJ,
		title: "FJ",
	},
	{
		img: FK,
		title: "FK",
	},
	{
		img: FL,
		title: "FL",
	},
	{
		img: FM,
		title: "FM",
	},
	{
		img: FN,
		title: "FN",
	},
	{
		img: FO,
		title: "FO",
	},
	{
		img: FP,
		title: "FP",
	},
	{
		img: FQ,
		title: "FQ",
	},
	{
		img: FR,
		title: "FR",
	},
	{
		img: FS,
		title: "FS",
	},
	{
		img: FT,
		title: "FT",
	},

	{
		img: FV,
		title: "FV",
	},
	{
		img: FW,
		title: "FW",
	},
	{
		img: FX,
		title: "FX",
	},
	{
		img: FY,
		title: "FY",
	},
	{
		img: FZ,
		title: "FZ",
	},
	{
		img: GA,
		title: "GA",
	},
	{
		img: GB,
		title: "GB",
	},
	{
		img: GC,
		title: "GC",
	},
	{
		img: GD,
		title: "GD",
	},
	{
		img: GE,
		title: "GE",
	},
	{
		img: GF,
		title: "GF",
	},
	{
		img: GG,
		title: "GG",
	},
	{
		img: GH,
		title: "GH",
	},
	{
		img: GI,
		title: "GI",
	},
	{
		img: GJ,
		title: "GJ",
	},
	{
		img: GK,
		title: "GK",
	},
	{
		img: GL,
		title: "GL",
	},
	{
		img: GM,
		title: "GM",
	},
	{
		img: GN,
		title: "GN",
	},
	{
		img: GO,
		title: "GO",
	},
	{
		img: GP,
		title: "GP",
	},
	{
		img: GQ,
		title: "GQ",
	},
	{
		img: GR,
		title: "GR",
	},
	{
		img: GS,
		title: "GS",
	},
	{
		img: GT,
		title: "GT",
	},
	{
		img: GV,
		title: "GV",
	},
	{
		img: GW,
		title: "GW",
	},
	{
		img: GX,
		title: "GX",
	},
	{
		img: GY,
		title: "GY",
	},
	{
		img: GZ,
		title: "GZ",
	},
	{
		img: HA,
		title: "HA",
	},
	{
		img: HB,
		title: "HB",
	},
	{
		img: HC,
		title: "HC",
	},
	{
		img: HD,
		title: "HD",
	},
	{
		img: HE,
		title: "HE",
	},
	{
		img: HF,
		title: "HF",
	},
	{
		img: HG,
		title: "HG",
	},
	{
		img: HH,
		title: "HH",
	},
	{
		img: HI,
		title: "HI",
	},
	{
		img: HJ,
		title: "HJ",
	},
	{
		img: HK,
		title: "HK",
	},
	{
		img: HL,
		title: "HL",
	},
	{
		img: HM,
		title: "HM",
	},
	{
		img: HN,
		title: "HN",
	},
	{
		img: HO,
		title: "HO",
	},
	{
		img: HP,
		title: "HP",
	},
	{
		img: HQ,
		title: "HQ",
	},
	{
		img: HR,
		title: "HR",
	},

	{
		img: HT,
		title: "HT",
	},
	{
		img: HV,
		title: "HV",
	},
	{
		img: HW,
		title: "HW",
	},
];
