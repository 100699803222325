import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Unstable_Grid2";
import coverup2 from "../images/coverup2.png";

function Banner() {
	return (
		<React.Fragment>
			<p
				class="marquee"
				style={{ padding: "3px", color: "red", fontSize: "14px" }}>
				<span>
					We now do Resin flooring - flakes and metallic epoxy
					coatings!
				</span>
			</p>
			<Grid id="home" container spacing={2}>
				<Grid xs={12}>
					<Card
						sx={{
							maxWidth: "65%",
							margin: "0 auto",
							boxShadow: "none",
						}}>
						<CardMedia
							component="img"
							height="auto"
							image={coverup2}
							alt="cover"
						/>
					</Card>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default Banner;
