import * as React from "react"; /* 
import Button from "@mui/material/Button"; */
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

export default function BasicMenu() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				size="large"
				edge="start"
				color="inherit"
				aria-label="menu"
				sx={{ mr: 2 }}>
				<MenuIcon />
			</IconButton>
			<Menu
				onClose={handleClose}
				onBlur={handleClose}
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
				<MenuItem
					onClick={() => {
						const anchor = document.querySelector("#home");
						anchor.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}}>
					Home
				</MenuItem>
				<MenuItem
					onClick={() => {
						const anchor = document.querySelector("#updates");
						anchor.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}}>
					Updates
				</MenuItem>
				<MenuItem
					onClick={() => {
						const anchor = document.querySelector("#testimonials");
						anchor.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}}>
					Testimonials
				</MenuItem>
				<MenuItem
					onClick={() => {
						const anchor = document.querySelector("#gallery");
						anchor.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}}>
					Gallery
				</MenuItem>
				<MenuItem
					onClick={() => {
						const anchor = document.querySelector("#ourlocation");
						anchor.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}}>
					Our Location
				</MenuItem>
				<MenuItem
					onClick={() => {
						const anchor = document.querySelector("#contactus");
						anchor.scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}}>
					Contact Us
				</MenuItem>
			</Menu>
		</div>
	);
}
