import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

function star(x) {
	return "★".repeat(x);
}

function ReviewCard(props) {
	return (
		<Card sx={{ minWidth: 275 }}>
			<CardContent>
				<Typography
					color="goldenrod"
					sx={{ fontSize: 25 }}
					gutterBottom>
					{star(props.stars)}
				</Typography>
				<Typography
					sx={{ fontSize: 12 }}
					color="text.secondary"
					gutterBottom>
					{props.time}
				</Typography>
				<Typography variant="body2">
					<br />"{props.review}"
				</Typography>
			</CardContent>
			<Typography
				sx={{ fontSize: 14 }}
				color="text.secondary"
				gutterBottom>
				-{props.author}
			</Typography>
			<CardContent sx={{ textAlign: "right" }}>
				<a style={{ textDecoration: "none" }} href={props.link}>
					See Review
				</a>
			</CardContent>
		</Card>
	);
}

export default ReviewCard;
