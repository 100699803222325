import "./App.css";

import Working from "./Components/Working";
import AllImages from "./Components/AllImages";
import { Route, Routes } from "react-router-dom";

function App() {
	return (
		<>
			<div className="App">
				<Routes>
					<Route path="/" element={<Working />} />
					<Route path="/images" element={<AllImages />} />
				</Routes>
			</div>
		</>
	);
}

export default App;
