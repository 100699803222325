import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function Nav2() {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				style={{
					backgroundColor: "white",
					color: "#e60000",
					width: "100%",
					position: "sticky",
				}}>
				<Toolbar>
					<div style={{ display: "flex", padding: "2%" }}>
						<a style={{ margin: "auto" }} href="/">
							←
						</a>
					</div>
					<Typography
						variant="h6"
						component="div"
						sx={{ flexGrow: 1, textAlign: "left" }}>
						Ingoglia Roofing Specialist Inc
					</Typography>
					<Button
						href="mailto:jingoglia0226@gmail.com"
						color="inherit">
						📅 Make Appointment
					</Button>
					<Button href="tel:+14078788724" color="inherit">
						📞 Call Now
					</Button>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default Nav2;
