import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function ActionAreaCard(props) {
	return (
		<Card
			style={{ display: "flex" }}
			sx={{
				maxWidth: "100vw",
				height: "100%",
				padding: "10px",
			}}>
			<CardActionArea>
				<CardContent>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						sx={{
							fontSize: "16px",
						}}>
						{props.title}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{props.text}
					</Typography>
					<CardMedia component="img" image={props.img} alt="" />
					<Button
						color="error"
						style={{
							position: "absolute",
							top: "0",
							right: "0",
						}}>
						Flip ⟳
					</Button>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
