import Nav from "../Components/Nav";
import Services from "../Components/Services";
import Banner from "./Banner";
import SocialDiv from "../Components/SocialDiv";
import MapPic from "../Components/MapPic";
import Reviews from "../Components/Reviews";
import ImgGallery from "../Components/ImgGallery";

function Working() {
	return (
		<div className="App">
			<Nav />
			<Banner />
			<Services />
			<Reviews />
			<ImgGallery />
			<MapPic />
			<SocialDiv />
		</div>
	);
}

export default Working;
