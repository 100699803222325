import React from "react";

function MapPic() {
	return (
		<>
			<h3
				style={{ fontSize: "1.5em", fontFamily: "Lucida Console" }}
				id="ourlocation">
				Our Location
			</h3>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14079.00967768854!2d-80.6939939!3d28.0930939!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8bc7c276dc333a7a!2sIngoglia%20Roofing%20Specialist%20Inc!5e0!3m2!1sen!2sus!4v1667436007641!5m2!1sen!2sus"
				width="90%"
				height="450"
				sx={{ border: "solid 1px black" }}
				allowfullscreen=""
				loading="lazy"
				referrerpolicy="no-referrer-when-downgrade"
				title="mappicture"></iframe>
		</>
	);
}

export default MapPic;
