import Nav2 from "../Components/Nav2";
import Banner from "../Components/Banner";
import ImgGallery2 from "../Components/ImgGallery2";

function AllImages() {
	return (
		<div className="AppAlt">
			<Nav2 />
			<Banner />
			<ImgGallery2 />
		</div>
	);
}

export default AllImages;
