import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import AAA from "../images/n2023/AAA.jpg";
import BBB from "../images/n2023/BBB.jpg";
import CCC from "../images/n2023/CCC.jpg";
import DDD from "../images/n2023/DDD.jpg";
import EEE from "../images/n2023/EEE.jpg";
import FFF from "../images/n2023/FFF.jpg";
import GGG from "../images/n2023/GGG.jpg";
import HHH from "../images/n2023/HHH.jpg";
import III from "../images/n2023/III.jpg";
import JJJ from "../images/n2023/JJJ.jpg";
import LLL from "../images/n2023/LLL.jpg";
import epoxy1 from "../images/n2023/epoxy1.jpeg";
import epoxy2 from "../images/n2023/epoxy2.jpeg";
import epoxy3 from "../images/n2023/epoxy3.jpeg";

function ImgGallery() {
	return (
		<>
			<h3
				style={{ fontSize: "1.5em", fontFamily: "Lucida Console" }}
				id="gallery">
				Gallery
			</h3>
			<ImageList
				sx={{ width: "90%", height: "90%", margin: "auto" }}
				cols={3}
				rowHeight={"30%"}>
				{itemData.map((item) => (
					<ImageListItem key={item.img}>
						<img
							style={{ border: "black solid 1px" }}
							src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
							srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
							alt={item.title}
							loading="lazy"
						/>
					</ImageListItem>
				))}
				<div style={{ display: "flex", border: "black solid 1px" }}>
					<a
						style={{ margin: "auto", fontSize: "1em" }}
						href="/images">
						See more
					</a>
				</div>
			</ImageList>
		</>
	);
}

export default ImgGallery;

const itemData = [
	{ img: epoxy1, title: "epoxy1" },
	{ img: epoxy2, title: "epoxy2" },
	{ img: epoxy3, title: "epoxy3" },
	{
		img: AAA,
		title: "AAA",
	},
	{
		img: BBB,
		title: "BBB",
	},
	{
		img: CCC,
		title: "CCC",
	},
	{
		img: DDD,
		title: "DDD",
	},
	{
		img: EEE,
		title: "22EEE",
	},

	{
		img: FFF,
		title: "FFF",
	},
	{
		img: GGG,
		title: "2GGG",
	},
	{
		img: HHH,
		title: "HHH",
	},
	{
		img: III,
		title: "III",
	},
	{
		img: JJJ,
		title: "JJJ",
	},
	{
		img: LLL,
		title: "LLL",
	},
];
